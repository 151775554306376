import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"

function dandelion({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.dandelion.coverCap")}}
                  title={t("items.dandelion.title")}>
    <Paragraph>{t("items.dandelion.p1")}</Paragraph>
    <ImageWithTitle style={{ padding: '0 10%'}} fluid={data.dandelion.childImageSharp.fluid} title={t("items.dandelion.cap1")}/>
  </Article>
}

export default i18nHOC(dandelion);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "dandelion/IMG_9845 (2).jpg" }) {
        ...CoverImage
    },
    dandelion: file(relativePath: { eq: "dandelion/DSC_1696.JPG" }) {
      ...FluidArticleImage
    }
  }
`